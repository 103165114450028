.social-media {
    display: flex;
    margin: 0;
    &__item {
        display: block;
        margin: 0 20px;
    }
    img {
        width: 40px;
    }
}