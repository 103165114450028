.single-project {
    padding: var(--header-height) 0;
    text-align: center;
    &__video {
        height: 0;
        padding-bottom: 56.25%;
        position: relative;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.project-image-grid {
    padding: 0;
    margin: 0 0 50px;
    &__wrapper {
        display: flex;
        width: auto;
    }
    picture {
        height: 0;
        position: relative;
        padding-bottom: 56.25%;
        width: 100%;
        overflow: hidden;
        border: 1px solid var(--primary-color);
        cursor: pointer;
    }
    &__column {
        &:nth-child(1) .project-image-grid__item:nth-child(1) picture,
        &:nth-child(3) .project-image-grid__item:nth-child(2) picture,
        &:nth-child(2) .project-image-grid__item:nth-child(3) picture {
            padding-bottom: 100%;
        }
    }
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform .5s ease;
    }
    &__column {
        background-clip: padding-box;
    }
    &__item {
        &:hover {
            img {
                transform: translate(-50%, -50%) scale(1.5);
            }
        }
    }
}