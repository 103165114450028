.menu-toggle {
    --size: 44px;
    background: none;
    border: none;
    line-height: 0;
    @media (min-width: 768px) {
        display: none;
    }
    svg {
        width: var(--size);
        height: var(--size);
        path {
            fill: var(--primary-color);
        }
    }
    &__close {
        display: none;
        .site-header--active & {
            display: block;
        }
    }
    &__burger {
        .site-header--active & {
            display: none;
        }
    }
}