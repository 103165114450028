.site-header {
    --height: 91px;
    --background: rgba(0, 0, 0, .25);
    --background-active: rgba(0, 0, 0, .8);
    $left-width: 3.25em;
    $right-width: 1.25em;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--background);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 2em;
    z-index: 1000;
    transition: background .25s ease;
    &--active {
        background-color: var(--background-active);
        @media (min-width: 768px) {
            background-color: var(--background);
        }
    }
    &__nav {
        a {
            display: inline-block;
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 2em;
            &.active {
                color: var(--primary-accent);
            }
            @media (min-width: 768px) {
                margin-left: 2em;
                margin-bottom: 0;
            }
        }
    }
    &__logo {
        padding: 4px;
        border: 3px solid var(--primary-color);
        display: flex;
        span {
            &:first-child {
                text-indent: 7px;
            }
            &:nth-child(even) {
                overflow: hidden;
                display: inline-block;
            }
        }
    }
    &__left {
        width: $left-width;
        animation: textShrinkAnimationLeft 1.5s forwards;
    }
    &__right {
        width: $right-width;
        animation: textShrinkAnimationRight 1.5s forwards;
    }
    &__left,
    &__right {
        animation-delay: 2s;
    }
    @keyframes textShrinkAnimationLeft {
        50% {
            width: $left-width;
        }
        100% {
            width: 0;
        }
    }
    @keyframes textShrinkAnimationRight {
        50% {
            width: $right-width;
        }
        100% {
            width: 0;
        }
    }
    &__nav {
        position: fixed;
        top: 91px;
        left: 0;
        width: 100vw;
        height: calc(100vh - var(--height));
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 100;
        background: var(--background-active);
        opacity: 0;
        pointer-events: none;
        transition: opacity .25s ease;
        .site-header--active & {
            opacity: 1;
            pointer-events: auto;
        }
        @media (min-width: 768px) {
            opacity: 1;
            pointer-events: auto;
            background: none;
            position: relative;
            height: auto;
            width: auto;
            display: block;
            transform: none;
            top: auto;
            left: auto;
        }
    }
    .video-modal--open & {
        background-color: var(--primary-bg);
    }
}