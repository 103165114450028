.video-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    text-shadow: 2px 2px 6px var(--primary-bg);
    .h3 {
        margin-bottom: 1em;
    }
}