.body-content {
    max-width: 800px;
    margin: 0 auto;
    a {
        color: var(--primary-accent);
        &:hover {
            color: var(--primary-color);
        }
    }
    p {
        margin-bottom: 1em;
    }
}