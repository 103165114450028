:root {
    --primary-color: #fff;
    --primary-accent: #9ba8ae;
    --primary-bg: #000;
}

body {
    background-color: var(--primary-bg);
    color: var(--primary-color);
}

a {
    color: var(--primary-color);
    transition: color .25s ease;
    &:hover {
        color: var(--primary-accent);
    }
}