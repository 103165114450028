@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Source+Sans+Pro:wght@300;400;700&display=swap');
:root {
    --header-font: 'Bebas Neue', sans-serif;
    --body-font: 'Source Sans Pro', sans-serif;
    --header-letter-spacing: .25em;
    --header-size: 4rem;
}

$header-sizes: ( "1": 50,
"2": 40,
"3": 30,
"4": 24,
"5": 20,
"6": 16);
@each $sizeName,
$sizeValue in $header-sizes {
    .h#{$sizeName} {
        font-size: #{$sizeValue}px;
        font-family: var(--header-font);
        letter-spacing: var(--header-letter-spacing);
    }
}