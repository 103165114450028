.button {
    border: 1px solid var(--primary-accent);
    line-height: 1;
    padding: .5em 1em;
    text-transform: uppercase;
    background-color: var(--primary-accent);
    text-shadow: none;
    transition: background-color .25s ease, border-color .25s ease;
    &:hover {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
    }
}