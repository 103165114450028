@use '_reset.scss';
@use '_typography.scss';
@use '_site-header.scss';
@use '_site-footer.scss';
:root {
    --header-height: 91px;
    --footer-height: 85px;
}

main {
    transition: filter .25s ease;
    padding: 91px 15px 0;
    min-height: calc(100vh - var(--footer-height));
    margin-bottom: var(--footer-height);
    .site-header--active+& {
        filter: blur(5px);
        overflow: hidden;
        @media (min-width: 768px) {
            filter: none;
        }
    }
}