.video-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -99;
    &__container,
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
    &__container {
        @media (min-aspect-ratio: 16/9) {
            height: 300%;
            top: -100%;
        }
        @media (max-aspect-ratio: 16/9) {
            width: 300%;
            left: -100%;
        }
    }
}