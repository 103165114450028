html {
    font-size: 10px;
}

body {
    font-family: var(--body-font);
    font-size: 1.6rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--header-font);
    letter-spacing: var(--header-letter-spacing);
}

a {
    text-decoration: none;
}