.video-modal {
    position: fixed;
    top: calc((var(--header-height) / 2) + 50%);
    left: 50%;
    width: 100vw;
    height: calc(100vh - var(--header-height));
    transform: translate(-50%, -50%);
    padding: 5%;
    z-index: 1000;
    background-color: var(--primary-bg);
    &__close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 44px;
        height: 44px;
        cursor: pointer;
        fill: var(--primary-color);
    }
    iframe {
        position: absolute;
        top: 5%;
        left: 5%;
        width: 90%;
        height: 90%;
    }
}