.site-footer {
    --background: rgba(0, 0, 0, .25);
    border-top: 1px solid var(--primary-accent);
    padding: 30px;
    background-color: var(--background);
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}